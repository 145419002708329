<template>
    <div class="wrap" id="wrap">
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch
              :filterTimeStr="thisMonth"
              v-model="queryParams"
              :getList="getList"
              :dropDownList="dropDownList"
              :isSearchInput="false"
              isSearchInputTitle="单据编号、订货仓库编号、名称、调出仓库编号、名称"
              @isShowHighCom="getIsShowHigh"
            />
            <div v-show="showHigh">
              <div class="marT10 x-f">
                <span class="fS14MR10">取货日期</span>
                <el-date-picker
                  style="width: 250px"
                  size="mini"
                  class="marR15"
                  v-model="queryParams.arrivalDate"
                  type="date"
                  placeholder="取货日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                
                <span class="fS14MR10">状态</span>
                <SelectLocal
                  :multiple="true"
                  v-model="queryParams.orderStatuss"
                  style="width: 250px"
                  :option="{ data: statusOption, value: 'value', label: 'label' }"
                />
                <span class="fS14MR10 marL15">预定门店</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.orderShopIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listShop',
                      option: {
                        filter: 'predetermineShopIds',
                        label: '预定门店',
                        seniorSearch: true,
                        option: {
                          multiple: true,
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择预定门店',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'shop' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
                <span class="fS14MR10 marL15">取货门店</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.pickUpStoreIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listShop',
                      option: {
                        filter: 'shopIds',
                        label: '取货门店',
                        seniorSearch: true,
                        option: {
                          multiple: true,
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择取货门店',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'shop' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </div>
              <div class="marT10">
                <span class="fS14MR10">配送模式</span>
                <SelectLocal
                  v-model="queryParams.fetchTypes"
                  style="width: 250px"
                  :option="{ remote: getDicts, remoteBody: 'fetch_type', value: 'dictValue', label: 'dictLabel' }"
                />
              </div>
              
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="单据信息" class="orderInfo">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 操作按钮 -->
            <operatingButton
              :getList="getList"
              :isExamineBtn="true"
              :multiple="multiple"
              :isCloseBtn="true"
              :isExportBtn="true"
              @handleAdd="handleAdd"
              @handleDelete="auditBill('删除')"
              @handleAudit="auditBill('审核')"
              @handleClose="auditBill('终止')"
              @handleExport="handleExport"
            >
              <template #specialDiyBtn>
                <div class="x-f marL10 marR10" style="align-items: center">
                  <PrintButton
                    :printTemplate="{
                      print: {},
                      label: '打印',
                      type: 'primary',
                      btnType: 'dropdown'
                    }"
                    :check="selectionList"
                  />
                </div>
              </template>
            </operatingButton>
            <el-table
              :data="tableData"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick"
              border
              v-loading="loadingTable"
              ref="multipleTable"
              :max-height="tableHeight"
              :height="tableHeight"
              style="width: 100%"
            >
              <el-table-column type="selection" width="50" align="center" />
              <template v-for="(item, index) in tableHeader">
                <el-table-column v-if="!['billNo'].includes(item.prop)" :key="index" :label="item.label"
                  align="center"
                  show-overflow-tooltip
                  :prop="item.prop"
                  :min-width="item.minWidth"
                  :type="item.type"
                />
                <el-table-column
                  v-if="item.prop === 'billNo'"
                  show-overflow-tooltip
                  label="单据编号"
                  align="center"
                  :prop="item.prop"
                  :min-width="item.minWidth"
                  :type="item.type"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="ids"
              :totalCom="total"
              @selectAllCom="selectAll"
            />
          </div>
        </template>
      </cardTitleCom>
    </div>
  </template>
  <script>
  import { listOrder, updateOrderStatus } from '@/api/shop/bill/reserveOrder' //门店预订单
  import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
  import seniorSearch from '@/views/components/seniorSearch' //高级搜索
  import bottomPage from '@/views/components/bottomPage' //底部分页
  import operatingButton from '@/views/components/operatingButton' //操作按钮
  import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
  import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
  import { getDicts } from '@/api/system/dict/data'
  export default {
    name: 'reserveOrder',
    dicts: ['bill_status'],
    components: {
      PrintButton: () => import('@/components/tablePage/tableButtons/print.vue'),
      cardTitleCom,
      seniorSearch,
      bottomPage,
      SelectRemote,
      operatingButton,
      SelectLocal
    },
    // 渲染该组件前调用这个路由钩子
    beforeRouteEnter (to, from, next) {
      //因为当守卫执行前,组件实例还没创建
      //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
      //通过'vm'才能访问组件this实例
      next(async vm => {
        //从首页待办业务进来
        if (to.query.billStatus && from.fullPath == '/index') {
          vm.queryParams = {
            pageNum: 1,
            pageSize: 15,
            billStatuss:[to.query.billStatus]
          },
          vm.thisMonth = ''
          vm.getList()
        }else{
          vm.thisMonth = 'thisMonth'
        }
      })
    },
    data () {
      return {
        loadingTable: false,
        statusOption: [
          { label: '全部', value: '1' },
          { label: '未接单', value: '2' },
          { label: '待配送', value: '3' },
          { label: '待自提', value: '4' },
          { label: '已取货', value: '5' },
          { label: '已完成', value: '6' },
          { label: '待退款', value: '10' },
          { label: '已退货', value: '7' },
          { label: '已拒单', value: '9' },
          
        ],
        //搜索框搜索的条件
        dropDownList: [
          { parameter: 'query', name: '全部' },
          { parameter: 'billNos', name: '单据编号' },
          { parameter: 'orderStoreNos', name: '订货仓库编号' },
          { parameter: 'orderStoreNames', name: '订货仓库名称' },
          { parameter: 'outStoreNos', name: '调出仓库编号' },
          { parameter: 'outStoreNames', name: '调出仓库名称' }
        ],
        tableHeader: [
          { tId: 1, label: '序号', prop: 'userId', width: '80', minWidth: '', align: '', type: 'index' },
          { tId: 2, label: '单据编号', prop: 'billNo', width: '', minWidth: '160', align: '' },
          { tId: 3, label: '单据日期', prop: 'billDate', width: '', minWidth: '120', align: '' },
          { tId: 4, label: '单据状态', prop: 'orderStatusName', width: '', minWidth: '120', align: '' },
          { tId: 5, label: '单据数量', prop: 'orderQty', width: '', minWidth: '160', align: '' },
          { tId: 6, label: '单据金额(元)', prop: 'orderPayMoney', width: '', minWidth: '160', align: '' },
          { tId: 7, label: '预定门店', prop: 'orderShopName', width: '', minWidth: '160', align: '' },
          { tId: 8, label: '取货/配送门店', prop: 'deliveryShopName', width: '', minWidth: '160', align: '' },
          { tId: 9, label: '客户名称', prop: 'receiverName', width: '', minWidth: '160', align: '' },
          { tId: 10, label: '联系电话', prop: 'receiverPhone', width: '', minWidth: '160', align: '' },
          { tId: 11, label: '取货方式', prop: 'fetchTypeName', width: '', minWidth: '120', align: '' },
          { tId: 12, label: '生产部门', prop: 'produceDeptName', width: '', minWidth: '120', align: '' },
          { tId: 13, label: '备注', prop: 'remark', width: '', minWidth: '155', align: '' },
          { tId: 14, label: '审核人', prop: 'auditBy', width: '', minWidth: '120', align: '' },
          { tId: 15, label: '审核时间', prop: 'auditTime', width: '', minWidth: '155', align: '' },
          { tId: 16, label: '修改人', prop: 'updateBy', width: '', minWidth: '120', align: '' },
          { tId: 17, label: '修改时间', prop: 'updateTime', width: '', minWidth: '155', align: '' },
          { tId: 18, label: '创建人', prop: 'createBy', width: '', minWidth: '120', align: '' },
          { tId: 19, label: '创建日期', prop: 'createTime', width: '', minWidth: '155', align: '' },
        ],
        thisMonth:'',
        selectionList: [],
        //是否显示高级搜索
        showHigh: false,
        ids: [], // 选中数组的Id
        billStatusZeroId: [], //选中数组的未审核的状态0
        billStatusTwoId: [], //选中数组的已审核的状态2
        billStatusThreeId: [], //选中数组的已删除的状态3
        billStatusCloseId: [], //选中数组的已删除的状态3
        total: 0, // 总条数
        loading: false, //加载框
        multiple: true, //删除、审核的禁用
        tableData: [], //表格
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 15
        },
        vivwH: 0, //页面高度
        searchH: 0, //搜索框高度
        paginationH: 40
      }
    },
    computed: {
      tableHeight () {
        return this.vivwH - this.paginationH - this.searchH - 130
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.vivwH = document.getElementById('wrap').clientHeight
        this.searchH = document.getElementById('search-card').clientHeight
      })
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize () {
        this.vivwH = document.getElementById('wrap').clientHeight
        this.searchH = document.getElementById('search-card').clientHeight
      },
      //是否显示高级搜索
      getIsShowHigh (value) {
        this.showHigh = value
        this.$nextTick(() => {
          this.handleResize()
        })
      },
      // 多选框选中数据
      handleSelectionChange (selection) {
        this.selectionList = selection
        this.ids = selection.map(item => item.billId) //单据id
        console.log('---selection-', selection);
        //筛选出来未审核的单据状态id0
        this.billStatusZeroId = selection
          // .map(item => {
          //   if (item.orderStatus === '0') {
          //     return item.billId
          //   }
          // })
        //   .filter(ite => typeof ite !== 'undefined')
        // //筛选出来已审核的单据状态id2
        this.billStatusTwoId = selection
          // .map(item => {
          //   if (item.orderStatus === '2') {
          //     return item.billId
          //   }
          // })
        //   .filter(ite => typeof ite !== 'undefined')
        // //筛选出来未删除的单据状态id3
        this.billStatusThreeId = selection
          // .map(item => {
          //   if ((item.orderStatus != '3') & (item.orderStatus === '0')) {
          //     return item.billId
          //   }
          // })
        //   .filter(ite => typeof ite !== 'undefined')
        this.billStatusCloseId = selection
          // .map(item => {
          //   if (!['0', '3', '4'].includes(item.orderStatus)) {
          //     return item.billId
          //   }
          // })
        //   .filter(ite => typeof ite !== 'undefined')
        this.multiple = !selection.length
      },
  
      //点击行选中
      handleRowClick (row) {
        // 获取表格对象
        // 切换行选中状态
        this.$refs.multipleTable.toggleRowSelection(row)
      },
      //点击全选所有数据
      async selectAll (value) {
        if (value) {
          this.loadingTable = true
          const res = await listOrder({
            ...this.queryParams,
            pageNum: 1,
            pageSize: this.total
          })
          //重新渲染表格
          this.tableData = res.rows
          //表格多选
          this.tableData.map(row => {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(row, value)
            })
          })
          this.loadingTable = false
        } else {
          //取消全选
          this.$nextTick(() => {
            this.$refs.multipleTable.clearSelection()
          })
          this.getList()
        }
      },
      //审核/删除/终止事件
      async auditBill (name) {
        try {
          await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          })
          console.log('-----name---', name, this.billStatusZeroId);
          if (name === '审核') {
            if (this.billStatusZeroId.length === 0) {
              this.$message.error('勾选单据中没有未审核的单据哦~')
              return
            }
            const obj = {
              billIds: this.billStatusZeroId.map(i => i.billId),
              billStatus: 2
            }
            //发送审核api
            await updateOrderStatus(obj)
            // 弹出提示
            this.$message.success('审核成功')
          } else if (name === '反审核') {
            if (this.billStatusTwoId.length === 0) {
              this.$message.error('勾选单据中没有已审核的单据哦~')
              return
            }
            const obj = {
              billIds: this.billStatusTwoId,
              billStatus: 0
            }
            //发送反审核api
            await updateOrderStatus(obj)
            // 弹出提示
            this.$message.success('反审核成功')
          } else if (['删除', '终止'].includes(name)) {
            if (name == '删除') {
              if (this.billStatusThreeId.length === 0) {
                this.$message.error('勾选单据中没有未审核的单据哦~')
                return
              }
            } else {
              if (this.billStatusCloseId.length === 0) {
                this.$message.error('勾选单据中没有未审核的单据哦~')
                return
              }
            }
            let dataIds = name == '删除' ? this.billStatusThreeId : this.billStatusCloseId
            const obj = {
              billIds: dataIds.map(i => i.billId),
              billStatus: name == '删除' ? 3 : 4
            }
            //发送删除api
            await updateOrderStatus(obj)
            // 弹出提示
            this.$message.success(`${name == '删除' ? '删除成功' : '终止成功'}`)
          }
          await this.getList()
        } catch {}
      },
      /** 查询分页列表 */
      async getList () {
        this.loadingTable = true
        const res = await listOrder(this.queryParams)
        this.tableData = res.rows
        this.total = res.total
        this.loadingTable = false
      },
      /** 导出按钮操作 */
      handleExport () {
        this.download(
          '/api/system/vip/base/vipcard/export',
          {
            ...this.queryParams
          },
          `post_${new Date().getTime()}.xlsx`
        )
      },
      //新增单据事件
      handleAdd () {
        this.$router.push({
          name: 'ReserveOrderDetail',
          query: {
            type: 'Add'
          }
        })
      },
      //跳转详情
      handleDetail (row) {
        const billId = row.billId
        this.$router.push({
          name: 'ReserveOrderDetail',
          query: {
            billId: billId,
            type: 'Update'
          }
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  //整体树
  .wrap {
    padding: 10px 10px 0 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
  
    // 搜索
    .navSearch {
      padding: 10px 10px 10px 20px;
    }
    .orderInfo {
      height: calc(100% - 94px);
      //源单信息表格
      .tableContent {
        margin: 6px 10px 10px 10px;
      }
    }
  }
  </style>
  
<template>
  <el-dialog
    title="选择收银员"
    :visible.sync="showDia"
    width="81%"
    :before-close="handleClose"
    v-dialogDrag
  >
    <div class="x-start">
      <div class="treeBox">
        <!-- 收银员用户组tree区域 -->
        <div>
          <div class="left-title marB10">
            <span>收银员用户组</span>
          </div>
          <el-input
            class="searchTree"
            v-model="userGroupName"
            placeholder="请输入收银员用户组"
            clearable
            size="small"
            prefix-icon="el-icon-search"
            style="margin-bottom: 10px"
          />
          <i
            class="el-icon-arrow-down iconDown"
            v-show="!isOpenTree"
            @click="clickOpenTree"
          ></i>
          <i
            class="el-icon-arrow-up iconDown"
            v-show="isOpenTree"
            @click="clickOpenTree"
          ></i>
          <el-tree
            node-key="id"
            :data="userGroupTreeOptions"
            :filter-node-method="filterNode"
            ref="tree"
            highlight-current
            @node-click="handleNodeClick"
            :default-expand-all="isExpand"
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="tableBox marL10">
        <!-- 收银员表格数据 -->
        <el-table
          v-loading="loading"
          :data="cashierList"
          @selection-change="handleSelectionChange"
          border
          height="500px"
          ref="multipleTable"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="50" align="center" />
          <el-table-column align="center" type="index" width="80" label="序号">
          </el-table-column>
          <el-table-column
            label="收银员编号"
            align="center"
            key="cashierNo"
            prop="cashierNo"
          >
          </el-table-column>
          <el-table-column
            label="收银员姓名"
            align="center"
            key="cashierName"
            prop="cashierName"
          />
          <el-table-column
            label="手机号"
            align="center"
            key="telephone"
            prop="telephone"
          />
          <el-table-column
            label="所属门店"
            align="center"
            key="shopNameStr"
            prop="shopNameStr"
          />
          <el-table-column label="删除状态" align="center" prop="delFlag">
            <template slot-scope="scope">
              <dict-tag
                :options="dict.type.App_del_flag"
                :value="scope.row.delFlag"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="center"
            key="remark"
            prop="remark"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="创建人"
            align="center"
            key="createBy"
            prop="createBy"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            show-overflow-tooltip
            width="155"
          >
          </el-table-column>
          <el-table-column
            label="修改人"
            align="center"
            key="updateBy"
            prop="updateBy"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="修改时间"
            align="center"
            prop="updateTime"
            show-overflow-tooltip
            width="155"
          >
          </el-table-column>
        </el-table>
        <!--               <div class="x-end" style="width: 100%;background-color: #ff3e54;height: 30px;">-->
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
        <!--               </div>-->
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDia = false">取 消</el-button>
      <el-button type="primary" @click="selectOK">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCashierTree } from '@/api/shop/base/shopCashierGroup'
import { listShopCashier } from '@/api/shop/base/shopCashier'

export default {
  name: 'cashierDialog',
  dicts: ['App_del_flag'],
  props: {
    showDia: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userGroupName: '', // 搜索收银员用户组字段
      isOpenTree: true, //一键展开图标
      userGroupTreeOptions: [], // 用户组数据
      isExpand: true, //是否一键展开

      // 遮罩层
      loading: false,
      cashierList: undefined, //收银员表格数据
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      // 收银员表格总条数
      total: 0
    }
  },
  created () {
    this.getList()
    this.getTreeselect()
    // this.getTenantEmployeeData()
    // this.getAllShopInfoData('')
  },
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    //一键展开
    clickOpenTree () {
      this.isOpenTree = !this.isOpenTree
      this.isExpand = !this.isExpand
      this.buildData()
    },
    /** 查询下拉树结构 */
    getTreeselect () {
      getCashierTree().then(response => {
        this.userGroupTreeOptions = response.data
      })
    },
    /** 查询收银员列表 */
    getList () {
      this.loading = true
      listShopCashier(this.queryParams).then(response => {
        this.cashierList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 用户组节点单击事件
    handleNodeClick (data) {
      this.queryParams.cashierGroupId = data.id
      this.getList()
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      // console.log('选择收银员：', selection)
      this.selectData = selection
    },
    // 确定选择，返回数据
    selectOK () {
      if (this.selectData.length === 1) {
        this.$emit('getSelectData', this.selectData)
      } else {
        this.$message.warning('只能选择一个收银员')
      }
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.treeBox {
  width: 230px;
  .left-title {
  }
}
.tableBox {
  width: calc(100% - 240px);
}
</style>

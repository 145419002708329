<template>
  <div class="theBody">
    <!-- 左边整体表格 -->
    <div class="right-body">
      <!-- 单据日期 -->
      <div class="bill-nav">
        <el-form
          :inline="true"
          ref="form"
          :model="queryParams"
          label-width="90px"
        >
          <el-form-item label="单据日期">
            <el-date-picker
              v-model="datetime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="20px">
            <el-radio-group v-model="queryParams.filterTime" size="medium">
              <el-radio-button label="today" style="max-width: 70px"
                >今天</el-radio-button
              >
              <el-radio-button label="yesterday" style="max-width: 70px"
                >昨天</el-radio-button
              >
              <el-radio-button label="thisWeek" style="max-width: 70px"
                >本周</el-radio-button
              >
              <el-radio-button label="lastWeek" style="max-width: 70px"
                >上周</el-radio-button
              >
              <el-radio-button label="thisMonth" style="max-width: 70px"
                >本月</el-radio-button
              >
              <el-radio-button label="lastMonth" style="max-width: 70px"
                >上月</el-radio-button
              >
              <el-radio-button label="thisSeason" style="max-width: 70px"
                >本季</el-radio-button
              >
              <el-radio-button label="lastSeason" style="max-width: 70px"
                >上季</el-radio-button
              >
              <el-radio-button label="thisYear" style="max-width: 70px"
                >本年</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="20px">
            <el-button
              type="primary"
              :icon="icon"
              size="mini"
              @click="showHighSearch"
              >高级搜索
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getList"
              >搜索
            </el-button>
          </el-form-item>

          <div class="x-w" v-show="showHigh">
            <el-form-item label="单据编号">
              <el-input
                class="vipNum"
                v-model="queryParams.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="制单人" prop="createEmployeeId">
              <el-select
                v-model="queryParams.createEmployeeId"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="item in employeeData"
                  :key="item.employeeId"
                  :label="item.employeeName"
                  :value="item.employeeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="仓库">
              <el-select
                v-model="queryParams.outStoreId"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="item in storeData"
                  :key="item.storeId"
                  :label="item.storeName"
                  :value="item.storeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据状态" prop="cardStatus">
              <el-select
                v-model="queryParams.billStatus"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="dict in dict.type.bill_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报损原因" prop="shop_scrap_type ">
              <el-select
                v-model="queryParams.scrapTypeId"
                placeholder="全部"
                clearable
                filterable
              >
                <el-option
                  v-for="dict in busiDicts.shop_scrap_type"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="marB15">
        <el-button
          class="marR15"
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增
        </el-button>
        <el-dropdown
          class="marR15"
          split-button
          type="primary"
          size="mini"
          plain
          icon="el-icon-circle-check"
          :disabled="multiple"
          @click="auditBill('审核')"
          @command="auditBill"
          >审核
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="反审核">反审核</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          class="marR15"
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="auditBill('删除')"
          >删除
        </el-button>
        <el-button
          class="marR15"
          plain
          icon="el-icon-refresh"
          size="mini"
          @click="test($event)"
          >刷新
        </el-button>
        <el-button
          class="marR15"
          v-show="false"
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleImport"
        >
          导入
        </el-button>
        <el-button
          class="marR15"
          v-show="false"
          type="warning"
          icon="el-icon-upload2"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </div>
      <div>
        <el-table
          v-loading="loading"
          ref="BillTable"
          tooltip-effect="dark"
          style="width: 100%"
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
          max-height="500"
          height="500"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="50" align="center" />
          <el-table-column
            label="序号"
            align="center"
            prop="userId"
            width="80"
            type="index"
          />
          <el-table-column
            show-overflow-tooltip
            label="单据编号"
            align="center"
            prop="billNo"
            width="155"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="handleDetail(scope.row)"
                target="_blank"
              >
                {{ scope.row.billNo }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="单据状态"
            align="center"
            prop="billStatusName"
          />
          <el-table-column label="报废原因" align="center" prop="scrapTypeId">
            <template slot-scope="scope">
              <DictBusiness
                :options="busiDicts.shop_scrap_type"
                :value="scope.row.scrapTypeId"
              />
            </template>
          </el-table-column>
          <el-table-column label="仓库" align="center" prop="outStoreName" />
          <el-table-column label="仓库编码" align="center" prop="outStoreNo" />

          <el-table-column label="审核人" align="center" prop="auditBy" />
          <el-table-column
            label="审核日期"
            align="center"
            prop="auditTime"
            width="155"
          />
          <el-table-column label="备注" align="center" prop="billRemark" />
          <el-table-column label="制单人" align="center" prop="createBy" />
          <el-table-column
            label="制单日期"
            align="center"
            prop="createTime"
            width="155"
          />
          <el-table-column label="修改人" align="center" prop="updateBy" />
          <el-table-column
            label="修改日期"
            align="center"
            prop="updateTime"
            width="155"
          />
        </el-table>
        <div class="bill-footer">
          <div class="left">
            <span class="select"
              >已选<span class="num">{{ ids.length }}</span
              >条</span
            >
            <span class="total">共{{ total }}条</span>
            <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
              >选择全部</span
            >
            <span class="checkAll" v-show="isCheckAll" @click="selectAll"
              >取消选择</span
            >
          </div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
    </div>

    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px">
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-checkbox v-model="upload.updateSupport" />
          是否更新已经存在的用户数据
          <el-link type="info" style="font-size: 12px" @click="importTemplate"
            >下载模板
          </el-link>
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xls”或“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
// 导入模板接口importTemplate
import { importTemplate } from '@/api/system/user' //导入接口
import { allStoreList } from '@/api/system/store' //仓库接口
import { allEmployeeList } from '@/api/system/employee' //制单人采购员
import { listOrder, updateList } from '@/api/shop/bill/scrap' //门店报损
import business from '@/utils/mixin/business' //业务字典
export default {
  name: 'scrap',
  dicts: ['bill_status'],
  mixins: [business],
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter (to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == '/index') {
        vm.queryParams.billStatus = to.query.billStatus
        vm.getList()
      }
    })
  },
  data () {
    return {
      employeeData: [], //制单人
      isCheckAll: false, //判断当前是否全选数据
      storeData: [], //仓库数据
      //高级搜索图标
      icon: 'el-icon-caret-bottom',
      //是否显示高级搜索
      showHigh: false,
      datetime: undefined, //单据查询时间
      ids: [], // 选中数组的Id
      billNo: [], //选中数组的编号
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      total: 0, // 总条数
      loading: false, //加载框
      multiple: true, //审核、删除的禁用
      tableData: [], //表格
      purAllList: [], //单据表格所有数据
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      }
    }
  },
  watch: {
    datetime (newVal) {
      if (newVal) {
        if (this.queryParams.filterTime) {
          this.queryParams.filterTime = undefined
        }
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
      }
      if (!newVal) {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
      }
    },
    //侦听时间
    'queryParams.filterTime': {
      handler (newVal) {
        if (newVal) {
          if (this.datetime) {
            this.datetime = undefined
            this.queryParams.beginBillDate = undefined
            this.queryParams.endBillDate = undefined
          }
          //日期快捷触发
          this.getList()
        }
      },
      immediate: true
    }
  },
  async created () {
    this.getTreeselect()
    this.getList()
    this.business('shop_scrap_type')
  },
  methods: {
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.BillTable.toggleRowSelection(row)
    },
    //点击全选所有数据
    async selectAll () {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        this.loading = true
        //获取全部采购订单数据
        const res = await listOrder({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.BillTable.toggleRowSelection(row, this.isCheckAll)
          })
        })
        this.loading = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.BillTable.clearSelection()
        })
        this.getList()
      }
    },
    //跳转详情
    handleDetail (row) {
      const billId = row.billId
      this.$router.push({
        name: 'ScrapDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    //删除/终止事件
    async auditBill (name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: 2,
            billType: 140304
          }
          //发送审核api
          await updateList(obj)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.billStatusTwoId.length === 0) {
            this.$message.error('勾选单据中没有已审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusTwoId,
            billStatus: 0,
            billType: 140304
          }
          //发送反审核api
          await updateList(obj)
          // 弹出提示
          this.$message.success('反审核成功')
        } else if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: 3,
            billType: '140304'
          }
          //发送删除api
          await updateList(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
        await this.getList()
      } catch {}
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'ScrapDetail'
      })
    },

    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      // 查询参数
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
      this.getList()
    },
    /** 查询分页列表 */
    async getList () {
      this.loading = true
      const res = await listOrder(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.loading = false
    },

    /** 获取查询数据 */
    async getTreeselect () {
      //获取仓库数据
      const res = await allStoreList()
      this.storeData = res.data
      //获取制单人数据
      const res1 = await allEmployeeList()
      this.employeeData = res1.data
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.billId) //单据id
      this.billNo = selection.map(item => item.billNo) //单据编号
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map(item => {
          if (item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if ((item.billStatus != '3') & (item.billStatus == '0')) {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //是否显示高级搜索
    showHighSearch () {
      this.icon =
        this.icon === 'el-icon-caret-bottom'
          ? 'el-icon-caret-top'
          : 'el-icon-caret-bottom'
      this.showHigh = !this.showHigh
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData () {
      for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = this.isExpand
      }
    },
    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick (data) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
      this.queryParams.vipLevelId = data.vipLevelId
      this.getList()
    },
    /** 导入按钮操作 */
    handleImport () {
      this.upload.title = '用户导入'
      this.upload.open = true
    },
    /** 下载模板操作 */
    importTemplate () {
      importTemplate().then(response => {
        this.download(response.msg)
      })
    },
    // 文件上传中处理
    handleFileUploadProgress (event, file, fileList) {
      this.upload.isUploading = true
    },

    // 文件上传成功处理
    handleFileSuccess (response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
      this.$refs.upload.clearFiles()
      this.$alert(response.msg, '导入结果', { dangerouslyUseHTMLString: true })
      this.getList()
    },
    // 提交上传文件
    submitFileForm () {
      this.$refs.upload.submit()
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/vip/base/vipcard/export',
        {
          ...this.queryParams
        },
        `post_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
//整体树
.theBody {
  margin: 20px;

  .right-body {
    display: flex;
    flex-direction: column;

    .bill-nav {
      //表头查询手机号
      .vipNum {
        margin-right: 1rem;
      }
    }

    // 表格
    .bill-footer {
      display: flex;
      justify-content: space-between;

      .left {
        padding: 32px 16px;

        .select {
          .num {
            color: #1890ff;
          }
        }

        .checkAll {
          color: #1890ff;
          cursor: pointer;
        }

        .total {
          margin: 0 10px;
        }
      }
    }
  }
}

::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
}

.el-radio-group {
  width: 600px;
}

::v-deep .el-radio-button {
  width: 11.11% !important;
}

::v-deep .el-radio-button__inner {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
</style>

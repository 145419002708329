<template>
  <div>
    <scrap-detail :billNo="type" />
  </div>
</template>

<script>
import scrapDetail from '@/views/components/shop/scrapDetail.vue'
export default {
  name: 'ScrapDetail',
  components: { scrapDetail },
  data () {
    return {
      type: '140304'
    }
  }
}
</script>

<style></style>

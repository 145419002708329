var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchase-instore" },
    [
      _c("master", {
        ref: "master",
        attrs: { billType: _vm.type },
        scopedSlots: _vm._u([
          {
            key: "tableMiddle",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "结算状态",
                    align: "center",
                    prop: "billStatus",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "仓库名称",
                    align: "center",
                    prop: "storeName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "单据金额",
                    align: "center",
                    prop: "billMoney",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "供应商名称",
                    align: "center",
                    prop: "partnerName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "供应商编码",
                    align: "center",
                    prop: "partnerNo",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "采购员",
                    align: "center",
                    prop: "purEmployee",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
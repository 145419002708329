<template>
  <!-- 采购入库单 -->
  <div class="purchase-instore">
    <master :billType="type" ref="master">
      <template #tableMiddle>
        <el-table-column label="结算状态" align="center" prop="billStatus" />
        <el-table-column label="仓库名称" align="center" prop="storeName" />
        <el-table-column label="单据金额" align="center" prop="billMoney" />
        <el-table-column label="供应商名称" align="center" prop="partnerName" />
        <el-table-column label="供应商编码" align="center" prop="partnerNo" />
        <el-table-column label="采购员" align="center" prop="purEmployee" />
      </template>
    </master>
  </div>
</template>

<script>
import master from '@/views/components/bill/master.vue'

export default {
  name: 'stockIn',
  components: { master },
    // 渲染该组件前调用这个路由钩子
    beforeRouteEnter (to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      //从首页待办业务进来
      if(to.query.billStatus && from.fullPath == '/index'){
        vm.$refs.master.purSelectList.billStatus = to.query.billStatus
        vm.getList()
      }
    })
  },
  data () {
    return {
      type: '140303',
    }
  }
}
</script>

<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { updateInstoreStatus } from "@/api/purchase/index";
import { listOrder, updateOrderStatus,copyShopPicking } from '@/api/shop/bill/waste' //门店原料消耗
export default {
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        exportOption: {
          show: true,
          exportApi: 'shopPickingExportList',
          exportName: '门店原料消耗列表',
          timeout: 60*5*1000
        },
        getListApi: listOrder,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/单据编号/仓库编号/仓库名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "outStoreNos", label: "仓库编号" },
              { filter: "outStoreNames", label: "仓库名称" },
            ],
          },
          
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          {
            label: "单据状态",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billStatuss",
            option: {
              remote: this.getDicts,
              remoteBody: "bill_status",
              dataKey: "data",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
              multiple: true
            },
          },
         
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoAudit",
                label: "反审核",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
           {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "outStoreNo",
            label: "仓库编号",
            minWidth: 100,
          },
          {
            prop: "outStoreName",
            label: "仓库名称",
            minWidth: 100,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 100,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatus: to.query.billStatus };
        vm.options.search[4].model = to.query.billStatus
        vm.options.search[1].defaultValue = ''
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let url = "/api/system/shop/bill/picking/updateBillStatus";
      let billType = "140308";
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "WasteDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "WasteDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData, url, billType);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, url, billType);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData, url, billType);
          }
          break;
         case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            copyShopPicking(billIds).then((res) => {
              this.$message.success('复制成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList();
            })
          }
          break;
        default:
          break;
      }
    },
    handleBillStatus(command, selectData, url, BillType, type) {
      let billData = []; //过滤的数据
      let name = ""; //要执行的状态名字
      let tag = ""; //提示的状态
      let billStatus = ""; //要执行的状态数字
      let billId = [];
      let billNo = selectData.map((i) => i.billNo);
      if (command === "反审核") {
        name = "反审核";
        tag = "已审核";
        billStatus = "0";
        billId = selectData.filter((item) => item.billStatus === '2').map((i) => i.billId)
        if (billId.length === 0) return this.$message.error('勾选单据中没有已审核的单据哦~')
      } else {
        if (command === "审核") {
          name = "审核";
          tag = "未审核";
          billStatus = "2";
          billId = selectData.filter((item) => item.billStatus === '0').map((i) => i.billId)
          if (billId.length === 0) return this.$message.error('勾选单据中没有未审核的单据哦~')
        } else if (command === "删除") {
          name = "删除";
          tag = "未审核";
          billStatus = "3";
          billId = selectData.filter((item) => item.billStatus != '3' && item.billStatus === '0').map((i) => i.billId)
          if (billId.length === 0) return this.$message.error('勾选单据中没有未审核的单据哦~')
        } else if (command === "关闭") {
          name = "关闭";
          tag = "未审核";
          billStatus = "4";
          billId = selectData.filter((item) => !['0', '3', '4'].includes(item.billStatus)).map((i) => i.billId)
          if (billId.length === 0) return this.$message.error('勾选单据中没有未审核的单据哦~')
        }
      }
      
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus([...billId], billStatus, url, BillType).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
